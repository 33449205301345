<template>
    <div>

      <h4>Description</h4>

      <rich-editor :content="module.description" @change="descriptionChange" :valid="descValid" invalid-message="Please enter a description for this learning module" />

      <div class="slides-header">
        <h4>Module slides</h4>
        <el-button type="primary" @click="addSlide">Add slide</el-button>
      </div>

      <el-table-draggable handle=".handle" @drop="dragSlide">
        <el-table :data="slideData">
          <el-table-column width="30">
            <template slot-scope="scope">
              <i class="handle el-icon-d-caret" />
            </template>
          </el-table-column>

          <el-table-column label="Slide title">
            <template slot-scope="scope">
              <div>
                {{scope.row.title}}
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Slide type" width="130">

            <template slot-scope="scope">
                <el-tag v-if="scope.row.type=='text'" :disable-transitions="true" type="info">
                  <i class="handle el-icon-tickets" /> Text
                </el-tag>
                <el-tag v-if="scope.row.type=='question' && !scope.row.profile_field" :disable-transitions="true" type="info">
                  <i class="handle el-icon-edit-outline" /> Question
                </el-tag>
                <el-tag v-if="scope.row.type=='question' && scope.row.profile_field" :disable-transitions="true" type="info">
                  <i class="handle el-icon-edit-outline" /> Profile Q
                </el-tag>
                <el-tag v-if="scope.row.type=='youtube'" :disable-transitions="true" type="info">
                  <i class="handle el-icon-video-camera" /> Video
                </el-tag>
                <el-tag v-if="scope.row.type=='image'" :disable-transitions="true" type="info">
                  <i class="el-icon-picture-outline" /> Image
                </el-tag>
                <el-tag v-if="scope.row.type=='pdf'" :disable-transitions="true" type="info">
                  <i class="handle el-icon-document" /> PDF
                </el-tag>
                <el-tag v-if="scope.row.type=='activity'" :disable-transitions="true" type="info">
                  <i class="handle el-icon-star-off" /> Activity
                </el-tag>
            </template>

          </el-table-column>

          <el-table-column align="center" width="70">
            <template slot-scope="scope">
              <el-button type="primary" icon="el-icon-edit" size="small" @click="editSlide(scope.row)"></el-button>
            </template>
          </el-table-column>

        </el-table>
      </el-table-draggable>

      <slide-modal :learning="true" @update="handleSlideUpdate" @delete="handleSlideDelete" />

    </div>
</template>

<script>
    import ElTableDraggable from 'element-ui-el-table-draggable';
    import {mixin as clickaway} from 'vue-clickaway';
    import moment from 'moment';
    import RichEditor from '@/views/components/RichEditor';
    import SlideModal from '@/views/components/SlideModal';

    export default {
        name: 'module-slides',
        components: {
          RichEditor,
          ElTableDraggable,
          SlideModal,
        },
        props: {
            module: {
                type: Object,
                default: null,
            },
            slides: {
              type: Array,
              default: null,
            },
            descValid: {
              type: Boolean,
              default: true,
            }
        },
        mixins: [clickaway],
        data() {
          return {
            description: null,
            slideData: [],
            deletedSlides: [],
            showSlideDialog: false,
            loading: false,
            newSlide: {
              id: null,
              temp_id: null,
              title: '',
              type: 'text',
              text: '',
              question: '',
              file_url: '',
              activity_id: '',
              profile_field: '', // reference to user profile field
            }
          }
        },
        watch: {
          slides(value) {
            this.slideData = [...value];
          }
        },
        mounted() {
        },
        methods: {
          descriptionChange(value) {
            this.$emit('descriptionChange',value);
          },
          editSlide(slide) {
            this.$bus.$emit('slide-modal', {...slide});
          },
          addSlide() {
            this.$bus.$emit('slide-modal', {...this.newSlide});
          },

          handleSlideUpdate(data) {
            var newData = [...this.slideData];
            // mark this slide as updated
            data.updated = true;

            // find slide in list
            var index = newData.findIndex(slide => {
              return ( (data.id && slide.id == data.id) || (data.temp_id && slide.temp_id == data.temp_id) );
            });
            if(index >=0) {
              // update existing slide
              newData[index] = data;
            }
            else {
              // add new slide
              newData.push(data);
            }
            this.slideData = newData;

            // notify parent list of changes
            this.$emit('updateSlides',this.slideData);
          },
          handleSlideDelete(data) {
            var newData = [...this.slideData];
            // mark this slide as updated
            data.updated = true;

            // remove slide from list
            const deleted = _.remove(newData, slide => {
              return ( (data.id && slide.id == data.id) || (data.temp_id && slide.temp_id == data.temp_id) );
            });
            this.slideData = newData;

            // mark for deletion if already stored
            if(data.id) {
              this.deletedSlides = [...this.deletedSlides, ...deleted];
            }

            // notify parent list of changes
            this.$emit('updateSlides',this.slideData,this.deletedSlides);
          },

          dragSlide(data) {
            // notify parent list of updated order
            this.$emit('updateSlides',data.list); // NOTE: should this be a different event?
          },

        },
    };
</script>

<style lang="scss" scoped>

    h4 {
      font-size: 16px;
      font-weight: 500;
      color: #606266;
      line-height: 40px;
      padding: 0 12px 0 0;
      margin: 0 0 0.5em;
    }

    .slides-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        h4 {
          margin: 0;
          flex-grow: 1;
        }
    }

    .el-tag {
        width: 100%;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 13px;
        font-weight: 500;
    }

    .el-alert {
        margin: 20px 0;
        border: 1px solid $red;
        padding: 15px 20px;
    }
    .participant-actions {
      margin-top: 10px;
    }
    .select-all {
      margin-left:10px;
    }
    .handle {
      cursor: pointer;
    }
    /deep/ .icon1 {
      background: #FAFAFA;
    }


</style>
