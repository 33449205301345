module/learning<template>
    <div class="app-container" v-loading="loading">

        <el-row :gutter="20" v-show="!loading">
            <el-col :span="8" class="module-panel">
                <module-panel
                        :module="module"
                        class="content"
                        @validate="validate"
                        @update="updateModule"
                        @delete="showDeleteDialog = true"
                        @changed="moduleChanged"
                        :updating="updating"
                        :deleting="deleting"
                />
            </el-col>
            <!-- SLIDES TEMPORARILY DISABLED
            <el-col :span="16" class="module-slides">
                <module-slides
                        :module="module"
                        :slides="slides"
                        :descValid="desc_valid"
                        @descriptionChange="descriptionChange"
                        @updateSlides="updateSlides"
                />
            </el-col>
            -->
        </el-row>

        <el-dialog class="deleteModuleDialog" :visible.sync="showDeleteDialog" title="Delete module">
          Are you sure you want to delete this module? This action cannot be undone.
          <div class="footer">
            <el-button @click="showDeleteDialog = false">Cancel</el-button>
            <el-button type="danger" @click="deleteModule">Delete</el-button>
          </div>
        </el-dialog>

    </div>
</template>

<script>

    import ModulePanel from '@/views/modules/ModulePanel';
    import ModuleSlides from '@/views/modules/ModuleSlides';
    import axios from 'axios';

    export default {
        name: 'module-profile',
        data() {
            return {
                module: {
                  id: null,
                  title: null,
                  image_url: null,
                  description: null,
                },
                slides: [],
                deletedSlides: [],
                loading: true,
                updating: false,
                deleting: false,

                desc_valid: null,

                changes: false,

                showDeleteDialog: false,
            };
        },
        components: {
            ModulePanel,
            ModuleSlides,
        },
        methods: {
          showChangeNotice() {
            this.changes = true;
            this.$bus.$emit('navbar-notice', 'You have unsaved changes pending for this module.');
          },
          hideChangeNotice() {
            this.changes = false;
            this.$bus.$emit('navbar-notice', null);
          },
          async getModule() {
            this.loading = true;

            if(this.module.id) {

              const token = await this.$auth.getTokenSilently();

              // get module:
              axios.get(`${process.env.VUE_APP_BASE_URI}/learning/${this.module.id}`,
                {
                  headers: {
                    'Authorization': `Bearer ${token}`
                  }
                }
              )
              .then(response => {
                  if(response.data.data) {
                    this.module = {...this.v, ...response.data.data };
                  }

                  // get slides:
                  axios.get(`${process.env.VUE_APP_BASE_URI}/learning/${this.module.id}/slides?includes[]=activity`,
                    {
                      headers: {
                        'Authorization': `Bearer ${token}`
                      }
                    }
                  )
                  .then(response => {
                      if(response.data.data) {
                        this.slides = [...this.slides, ...response.data.data.slides ];
                      }
                      this.loading = false;
                  })
                  .catch((error) => {
                      this.loading = false;
                      console.log("API error: get slides",error,error.response);
                  });
              })
              .catch((error) => {
                  this.loading = false;
                  console.log("API error: get module",error,error.response);
              });

            }
            else {
              this.loading = false;
            }

            
          },

          validate() {
            // validate any other items
            if(!this.module.description) {
              this.desc_valid = false;
            }
            else {
              this.desc_valid = true;
            }
          },

          async updateModule(moduleData) {
            // main form is submitted and valid; check description as well
            
            /* disabled in this release
            if(!this.module.description) {
              console.log('no description');
              this.desc_valid = false;
              return;
            }
            else {
            */
            if(this.module) {

              const token = await this.$auth.getTokenSilently();

              this.desc_valid = true;

              this.updating = true;
              // merge existing & updated module data
              this.module = { ...this.module, ...moduleData };

              // copy of module for update:
              var updateModule = {...this.module};

              // remove image_url from submitted data, use supplied file if present
              delete updateModule.image_url;

              // send changes to endpoint

              var formData = new FormData();
              for(const prop in updateModule) {
                const propValue = updateModule[prop];
                if(Array.isArray(propValue)) {
                  for (var i=0; i < propValue.length; i++) {
                    formData.append(`${prop}[]`, propValue[i]);
                    console.log("Data:",prop,propValue[i]);
                  }
                }
                else {
                  formData.append(prop,updateModule[prop]);
                }
              }

              var config = {
                url:`${process.env.VUE_APP_BASE_URI}/learning`,
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${token}`
                },
                data: formData,
                method:'post',
              }

              if(updateModule.id) {
                config.url += "/" + updateModule.id;
                formData.append('_method','put');
              }
              axios(config)
              .then(response => {
                  // set ID if new module was created
                  if(!this.module.id) {
                    this.module.id = response.data.data.id;

                    // SLIDES TEMPORARILY DISABLED
                    //this.saveSlides(this.module.id);
                  }
                  // else {
                  //   this.saveSlides();
                  // }

                  this.updating = false; // remove when slides re-enabled

                  this.$router.push(`/modules`);
              })
              .catch((error) => {
                  //this.module = null;
                  this.updating = false;
                  console.log("API error: update module",error,error.response);
                  if(error.response.status == 422) {
                    this.$message.error(
                        'Unable to upload thumbnail image. Please check your image meets the upload requirements.',
                    );
                  }

              });

            }
          },
          async deleteModule() {
            const token = await this.$auth.getTokenSilently();
            axios.delete(`${process.env.VUE_APP_BASE_URI}/activities/${this.module.id}`,
              {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              }
            )
            .then(response => {
                console.log("deleted module",response);
                this.$router.push(`/modules`);
            })
            .catch((error) => {
                console.log("API error: delete module",error,error.response);
            });
          },
          moduleChanged(changed) {
            if(changed) {
              this.showChangeNotice();
            }
            else this.hideChangeNotice();
          },

          descriptionChange(description) {
            if(description) this.desc_valid = true;
            else this.desc_valid = false;
            this.showChangeNotice();
            this.module.description = description;
          },
          updateSlides(slides,deleted) {
            this.showChangeNotice();
            this.slides = slides;
            if(deleted) {
              this.deletedSlides = deleted;
            }
          },

          async saveSlides(module_id) {
            // delete all removed slides

            const token = await this.$auth.getTokenSilently();

            for(let i=0;i<this.deletedSlides.length;i++) {
              let slide = this.deletedSlides[i];

              await axios.delete(`${process.env.VUE_APP_BASE_URI}/learning/${this.module.id}/slides/${slide.id}`,
                {
                  headers: {
                    'Authorization': `Bearer ${token}`
                  }
                }
              )
              .then(response => {
                  console.log("deleted slide",slide,response);
              })
              .catch((error) => {
                  console.log("API error: delete slide",error,error.response);
              });
            }
            this.deletedSlides = [];

            // update all modified slides
            for(let i=0;i<this.slides.length;i++) {
              let slide = this.slides[i];

              if(slide.updated) {
                var formData = new FormData();
                formData.append('type',slide.type);
                formData.append('title',slide.title);
                // append type-relevant properties only
                if(slide.type == 'text') {
                  formData.append('text',slide.text);
                }
                else if(slide.type == 'question') {
                  formData.append('question',slide.question);
                  if(slide.question_field_id) {
                    formData.append('question_field_id',slide.question_field_id);
                  }
                }
                else if(slide.type == 'youtube') {
                  formData.append('file_url',slide.file_url);
                }
                else if(slide.type == 'image' || slide.type == 'pdf') {
                  formData.append('file',slide.file);
                }
                else if(slide.type == 'activity') {
                  if(slide.activity_prompt) {
                    formData.append('activity_prompt',slide.activity_prompt);
                  }
                  formData.append('activity_id',slide.activity_id);
                }

                var config = {
                  url:`${process.env.VUE_APP_BASE_URI}/learning/${this.module.id}/slides`,
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                  },
                  data: formData,
                  method:'post',
                }

                if(slide.id) {
                  config.url += "/" + slide.id;
                  formData.append('_method','put');
                }

                await axios(config)
                .then(response => {
                    // set ID if new slide was created
                    if(!slide.id) { slide.id = response.data.data.id }
                })
                .catch((error) => {
                    console.log("API error: save slide",error,error.response);
                });
              }

            }

            // update slide order
            if(this.slides && this.slides.length) {
              const order = { ids: this.slides.map(slide => { return slide.id; }) };
              await axios.put(`${process.env.VUE_APP_BASE_URI}/learning/${this.module.id}/slides/order`,
                order,
                {
                  headers: {
                    'Authorization': `Bearer ${token}`
                  }
                }
              )
              .then(response => {
                  console.log("updated order",order,response);
              })
              .catch((error) => {
                  console.log("API error: update order",error,error.response);
              });

              this.updating = false;
              this.hideChangeNotice();

              if(module_id) {
                // newly created module
                this.$router.push(`/module/${module_id}`)
              }
            }
            else this.updating = false;
          }

        },
        mounted() {
          this.module.id = this.$route.params.module_id;

          this.getModule();

        },

        beforeRouteLeave(to,from,next) {
          if(this.changes) {
            const answer = window.confirm('Are you sure you want to leave? Your unsaved changes will be lost.');
            if(answer) {
              this.hideChangeNotice()
              next();
            }
            else {
              next(false);
            }
          }
          else {
            this.hideChangeNotice();
            next();
          }
        },
    };

</script>

<style rel="stylesheet/scss" lang="scss" scoped>

    .app-container {
        padding: 0;
        min-height: calc(100vh - 50px);
        overflow: hidden;

        // for minimal view
        background-color: white;

        .el-row {
            margin: 0 !important;
        }
    }

    .module-panel {
        padding: 10px 40px 50px 40px !important;
        box-sizing: border-box;
        background-color: white;
        height: calc(100vh - 50px);
        overflow: scroll;

        // for minimal view
        float: none;
        margin: 0 auto;
    }

    .module-slides {
        padding: 10px 40px !important;
        box-sizing: border-box;
        background-color: #F0F2F5;
        height: calc(100vh - 50px);
        overflow: scroll;
    }

    .deleteModuleDialog {
      .footer {
        margin-top: 20px;
        display: flex;
        > * {
          flex-grow: 1;
        }
      }
    }
</style>

<style lang="scss">
  .deleteModuleDialog .el-dialog {
    width: 640px;
    max-width: calc(100% - 40px);
  }
</style>
