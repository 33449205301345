var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
          attrs: { gutter: 20 },
        },
        [
          _c(
            "el-col",
            { staticClass: "module-panel", attrs: { span: 8 } },
            [
              _c("module-panel", {
                staticClass: "content",
                attrs: {
                  module: _vm.module,
                  updating: _vm.updating,
                  deleting: _vm.deleting,
                },
                on: {
                  validate: _vm.validate,
                  update: _vm.updateModule,
                  delete: function ($event) {
                    _vm.showDeleteDialog = true
                  },
                  changed: _vm.moduleChanged,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "deleteModuleDialog",
          attrs: { visible: _vm.showDeleteDialog, title: "Delete module" },
          on: {
            "update:visible": function ($event) {
              _vm.showDeleteDialog = $event
            },
          },
        },
        [
          _vm._v(
            " Are you sure you want to delete this module? This action cannot be undone. "
          ),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDeleteDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.deleteModule } },
                [_vm._v("Delete")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }