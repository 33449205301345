<template>
  <div>

        <div class="header">
          <h3>{{module.id ? 'Edit' : 'Add'}} Learning Module</h3>
          <el-button type="primary" :loading="updating" @click="handleSubmit">
              Save{{moduleData.id ? ' changes' : ''}}
          </el-button>
        </div>

        <el-form autoComplete="on" :model="moduleData" :rules="rules" ref="form" label-position="left" label-width="135px">

            <el-form-item prop="title" label="Title">
                <el-input name="title" type="text" required v-model="moduleData.title" autoComplete="on" placeholder="Module title" />
            </el-form-item>

            <el-form-item prop="summary" label="Summary">
              <el-input name="summary" type="textarea" v-model="moduleData.summary" autoComplete="on" placeholder="Short summary" maxlength="80" show-word-limit />
            </el-form-item>

            <el-form-item prop="video_url" label="Youtube video ID">
                <el-input name="video_url" type="text" required v-model="moduleData.video_url" autoComplete="on" placeholder="Video URL" />
            </el-form-item>

            <el-form-item class="imageUpload" prop="image_url" label="Thumbnail">
                <file-upload filetype="image"
                  :current="moduleData.image_url"
                  @select="handleImageSelected"
                  @remove="handleImageRemoved"
                />
                <div class="footnote">File size must be less than 2MB.</div>
            </el-form-item>
            
          </el-form>

          <div class="form-footer">
              <el-button type="primary" :loading="updating" @click="handleSubmit">
                  {{moduleData.id ? 'Update' : 'Save'}} module
              </el-button>
              <hr>
              <el-button v-if="moduleData.id" type="danger" :loading="deleting" @click="handleDelete">
                  Delete module
              </el-button>
          </div>
  </div>
</template>

<script>
    import FileUpload from '@/views/components/FileUpload';

    export default {
        name: 'module-panel',
        props: {
            module: {
                required: true,
                type: Object,
            },
            updating: {
                required: false,
                type: Boolean,
            },
            deleting: {
                required: false,
                type: Boolean,
            },
        },
        components: {
            FileUpload,
        },
        data() {
            return {
              moduleData: {
                id: null,
                title: null,
                image_url: null,
                file: null, // image to upload
                summary: null,
                video_url: null,
              },
              moduleInitial: null,
              rules: {
                  title: [{
                      required: true,
                      trigger: 'blur',
                      message: 'Module title is required',
                  }],
                  image_url: [{
                      required: true,
                      validator: (rule,value,callback) => {
                        // either an existing `image_url` should be set, or a new file has been selected
                        if(!this.moduleData.file && !value) {
                          callback(new Error('Module image is required'));
                        }
                        else callback();
                      },
                      trigger: 'blur',
                  }],
                  summary: [{
                      required: true,
                      trigger: 'blur',
                      message: 'Module summary is required',
                  }],
                  video_url: [{
                      required: true,
                      trigger: 'blur',
                      validator: (rule,value,callback) => {
                        // 'video_url' must be set, and must not contain a slash
                        if(!value) {
                          callback(new Error('Youtube video ID is required'));
                        }
                        else if(value.includes('/')) {
                          callback(new Error('Please enter a valid Youtube video ID'));
                        }
                        else callback();
                      },
                  }],
              },

              loading: false,

              showDeleteDialog: false,
            };
        },
        watch: {
          module(value) {
            var newData = {...value};
            // removed description from this component; handled elsewhere
            delete newData.description;

            this.moduleData = newData;

            // for monitoring if changes have occurred
            this.moduleInitial = {...newData};
          },
          moduleData: {
            // watch moduleData to flag changes
            handler(value) {
              if(this.moduleInitial) {
                if(JSON.stringify(value) != JSON.stringify(this.moduleInitial)) {
                  this.$emit('changed',true);
                }
                else this.$emit('changed',false);
              }
            },
            deep: true
          }
        },
        methods: {
          handleSubmit() {
            this.$refs.form.validate((valid) => {
              if(valid) {
                this.$emit('update',this.moduleData);
              }
              else {
                console.log("Form is not valid");
                this.$emit('validate');
                return false;
              }
            });

          },
          handleDelete() {
            // pop up warning first
            this.$emit('delete');
          },

          handleImageSelected(file) {
            this.moduleData.file = file;
          },
          handleImageRemoved() {
            this.moduleData.image_url = null;
            this.moduleData.file = null;
          },
        },
    };
</script>

<style lang="scss" scoped>
    .content {
      padding-bottom: 40px;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      h3 {
        margin: 0;
      }
    }

    hr {
        border: none;
        background: none;
        border-top: 1px solid #EEF1F6;
        margin: 20px 0;
    }

    .dateseparator {
      text-align: center;
    }

    .info {
        margin: 60px 0 20px 0;
        font-size: 14px;
        opacity: 0.3;
    }

    .el-select {
      width: 100%;
    }

    .el-date-editor {
        width: 100%;
    }

    .disabled {
        pointer-events: none;
    }
    .el-tag {
        width: 100%;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 13px;
        font-weight: 500;
    }
    /deep/ .el-textarea__inner {
      padding-right: 50px;
    }

    .current {
      font-weight: bold;
    }
    .subscription-add {
      text-align: center;
      margin-top: 20px;
    }
    .module-row {
      display: flex;
      margin-bottom: 10px;
      .module-title {
        width: 150px;
      }
      .el-progress {
        width: calc(100% - 150px);
      }
    }

    .form-section-label {
      font-size: 14px;
      font-weight: 500;
      color: #606266;
      line-height: 40px;
      padding: 0 12px 0 0;
    }

    /deep/ .el-form-item.label-top {
      .el-form-item__label {
        width: auto!important;
        float: none;
        display: inline-block;
        text-align: left;
        padding: 0;
      }
      .el-form-item__content {
        margin-left: 0!important;
      }
    }

    /deep/ .evidence .el-rate {
      margin: 10px 0;
    }

    .form-footer {
      .el-button {
        display: block;
        width: 100%;
      }
    }

    .footnote {
      font-size: 12px;
      line-height: 2em;
      color: #909399;
    }

</style>
<style lang="scss">
  .imageUpload .el-form-item__content {
    line-height: 0;
  }
</style>