var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("h3", [
            _vm._v(_vm._s(_vm.module.id ? "Edit" : "Add") + " Learning Module"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.updating },
              on: { click: _vm.handleSubmit },
            },
            [
              _vm._v(
                " Save" + _vm._s(_vm.moduleData.id ? " changes" : "") + " "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            autoComplete: "on",
            model: _vm.moduleData,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "135px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "title", label: "Title" } },
            [
              _c("el-input", {
                attrs: {
                  name: "title",
                  type: "text",
                  required: "",
                  autoComplete: "on",
                  placeholder: "Module title",
                },
                model: {
                  value: _vm.moduleData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.moduleData, "title", $$v)
                  },
                  expression: "moduleData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "summary", label: "Summary" } },
            [
              _c("el-input", {
                attrs: {
                  name: "summary",
                  type: "textarea",
                  autoComplete: "on",
                  placeholder: "Short summary",
                  maxlength: "80",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.moduleData.summary,
                  callback: function ($$v) {
                    _vm.$set(_vm.moduleData, "summary", $$v)
                  },
                  expression: "moduleData.summary",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "video_url", label: "Youtube video ID" } },
            [
              _c("el-input", {
                attrs: {
                  name: "video_url",
                  type: "text",
                  required: "",
                  autoComplete: "on",
                  placeholder: "Video URL",
                },
                model: {
                  value: _vm.moduleData.video_url,
                  callback: function ($$v) {
                    _vm.$set(_vm.moduleData, "video_url", $$v)
                  },
                  expression: "moduleData.video_url",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "imageUpload",
              attrs: { prop: "image_url", label: "Thumbnail" },
            },
            [
              _c("file-upload", {
                attrs: { filetype: "image", current: _vm.moduleData.image_url },
                on: {
                  select: _vm.handleImageSelected,
                  remove: _vm.handleImageRemoved,
                },
              }),
              _c("div", { staticClass: "footnote" }, [
                _vm._v("File size must be less than 2MB."),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.updating },
              on: { click: _vm.handleSubmit },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.moduleData.id ? "Update" : "Save") + " module "
              ),
            ]
          ),
          _c("hr"),
          _vm.moduleData.id
            ? _c(
                "el-button",
                {
                  attrs: { type: "danger", loading: _vm.deleting },
                  on: { click: _vm.handleDelete },
                },
                [_vm._v(" Delete module ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }